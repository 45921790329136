import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const ContactPage = () => {
  return (
    <Layout hero={<HeroImage title="Terms & Conditions" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <Row>
            <Col sm={12}>
              <div className="title-container mb-3">
                <h3 className="title">
                  Privacy Statement
                </h3>
              </div>
              <p>
                This web site and its contents (“Web Site“) are owned and operated by BlueScope Distribution Pty Limited ABN 88 096 380 068 of 88 Ricketts Rd, Mt Waverley, Victoria, Australia 3149 (“Metalcorp Steel“), a fully owned subsidiary of BlueScope Steel Limited ABN 16 000 011 058 (“BlueScope Steel”).
              </p>
              <p>
                The way in which Metalcorp Steel manages your personal information which is collected by Metalcorp Steel, including from your use of the Web Site, is governed by the privacy policy of BlueScope Steel.
              </p>
              <p>BlueScope Steel's privacy policy (“Privacy Statement”) can be accessed <a target="_blank" href="http://www.bluescopesteel.com.au/footer/privacy">here.</a></p>
              <div className="title-container mb-3">
                <h3 className="title">
                  Your Agreement
                </h3>
              </div>
              <p>By using this Web Site you acknowledge that you have read and understood the Privacy Statement and agree with and consent to the practices described in the Privacy Statement.</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default ContactPage

export const Head = () => {
  return (
    <>
      <title>Privacy Policy | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}
